import React from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import { theme } from "common/theme/business";
import { ResetCSS } from "common/assets/css/style";
import Sticky from "react-stickynode";
import Navbar from "containers/Business/Navbar";
import Banner from "containers/Business/Banner";
import KeyFeatures from "containers/Business/KeyFeatures";
import ChooseUs from "containers/Business/ChooseUs";
import Experiences from "containers/Business/Experience";
import FeatureSlider from "containers/Business/FeatureSlider";
import Pricing from "containers/Business/Pricing";
import Testimonials from "containers/Business/Testimonials";
import AvailableStore from "containers/Business/AvailableStore";
import Faqs from "containers/Business/Faq";
import ContactUs from "containers/Business/ContactUs";
import Footer from "containers/Business/Footer";
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "containers/Business/business.style";
import "@redq/reuse-modal/es/index.css";

import Seo from "components/seo";

const Business = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="Mercadia Negocios" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />
            <KeyFeatures />
            {/* <ChooseUs /> */}
            {/* <Experiences /> */}
            {/* <FeatureSlider /> */}
            {/* <Pricing /> */}
            {/* <Testimonials /> */}
            <AvailableStore />
            <Faqs />
            <ContactUs />
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};
export default Business;
