import React from "react";

import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";

import SectionWrapper, { Content, ContentWrap } from "./contactUs.style";

const ContactUs = () => {
  return (
    <SectionWrapper>
      <Container>
        <Content>
          <ContentWrap>
            <Heading
              as="h3"
              content="🖐️ Si tiene alguna pregunta no dude en ponerse en contacto con nosotros"
            />
          </ContentWrap>
        </Content>
      </Container>
    </SectionWrapper>
  );
};

export default ContactUs;
