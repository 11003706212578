import React from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import { Icon } from "react-icons-kit";
import { playCircle } from "react-icons-kit/fa/playCircle";
import PlayButton from "common/assets/image/playstore.svg";
import { openModal, closeModal } from "@redq/reuse-modal";
import Text from "common/components/Text";
import Button from "common/components/Button";
import Image from "common/components/Image";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import GatsbyImage from "common/components/GatsbyImage";
import BannerWrapper, {
  BannerContent,
  BannerImage,
  BannerImageMobile,
  ButtonGroup,
  VideoWrapper,
} from "./banner.style";

// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube.com/embed/hW98BFnVCm8"
      frameBorder="0"
    />
  </VideoWrapper>
);

const Banner = () => {
  const Data = useStaticQuery(graphql`
    query {
      appScreenshot: file(
        relativePath: { eq: "image/business/bannerImg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1252
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      bannerImgMobile: file(
        relativePath: { eq: "image/business/banner-single.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 577
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  const toPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=co.mercadia.store",
      "_blank"
    );
  };

  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        default: {
          width: "auto",
          height: "auto",
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="Impulse su negocio con pedidos en línea 🔥"
            />
          </Fade>
          <Fade up delay={200}>
            <Text content="Reciba pedidos fácilmente con Mercadia Negocios, envíelos o permita recoger en tienda." />
          </Fade>
          <Fade up delay={300}>
            <div className="TrustedImageBtn">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=co.mercadia.store"
              >
                <Image
                  src={PlayButton}
                  className="play_image_area"
                  alt="GooglePlay Image"
                />
              </a>
            </div>

            {/* <ButtonGroup>
              <Button
                onClick={toPlayStore}
                className="primary"
                title="Descargar"
              />
              <Button
                className="text"
                variant="textButton"
                icon={<Icon icon={playCircle} />}
                iconPosition="left"
                title="How it works"
                onClick={handleVideoModal}
              />
            </ButtonGroup> */}
          </Fade>
        </BannerContent>
        <BannerImage>
          <GatsbyImage
            src={
              (Data.appScreenshot !== null) | undefined
                ? Data.appScreenshot.childImageSharp.gatsbyImageData
                : {}
            }
            alt="App Screenshot"
          />
        </BannerImage>
        <BannerImageMobile>
          <GatsbyImage
            src={
              (Data.bannerImgMobile !== null) | undefined
                ? Data.bannerImgMobile.childImageSharp.gatsbyImageData
                : {}
            }
            alt="App Screenshot"
          />
        </BannerImageMobile>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
